import StorageHelper from './storageHelper';
import { KEY_TO_STORAGE_DEMO_USER } from './settings';
import data from './tokenJwt';

export default class CognitoUser {
  constructor({ Username, Pool, Storage }) {
    this.Username = Username;
    this.Pool = Pool;
    this.isValid = true;
    this.storage = Storage || new StorageHelper().getStorage();
  }

  authenticateUser(authDetails, callback) {
    const users = {
      comercial: 'com1011',
      demo: '2112',
      demo9: '1335',
    };
    const listUsers = Object.keys(users);
    const isUsernameValid = listUsers.includes(this.Username);
    const isPasswordValid = authDetails.Password === users[this.Username];

    if (isUsernameValid && isPasswordValid) {
      this.storage.set(`${KEY_TO_STORAGE_DEMO_USER}`, data);
      return callback.onSuccess(data);
    }
    callback.onFailure(new Error('Error on mockable Cognito ser'));
    return null;
  }

  signOut() {
    this.Username = null;
    this.Pool = null;
    this.storage.clear();
  }

  getSession(callback) {
    return callback(null, { isValid: () => this.isValid });
  }
}
