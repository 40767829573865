function tokenFactory() {
  function setAccessToken(accessToken) {
    window.localStorage.setItem('accessToken', JSON.stringify(accessToken));
  }

  function setIdToken(idToken) {
    window.localStorage.setItem('idToken', JSON.stringify(idToken));
  }

  function setRefreshToken(refreshToken) {
    window.localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
  }

  function getAccessToken() {
    return JSON.parse(window.localStorage.getItem('accessToken'));
  }

  function getIdToken() {
    return JSON.parse(window.localStorage.getItem('idToken'));
  }

  function getRefreshToken() {
    const result = JSON.parse(window.localStorage.getItem('refreshToken'));
    return result?.token;
  }

  function getUserName() {
    const userData = JSON.parse(window.localStorage.getItem('accessToken'));
    return userData?.payload?.username;
  }

  function removeAll() {
    window.localStorage.clear();
  }

  return {
    getAccessToken,
    getIdToken,
    getRefreshToken,
    getUserName,
    setAccessToken,
    setIdToken,
    setRefreshToken,
    removeAll,
  };
}

const token = tokenFactory();

export default token;
