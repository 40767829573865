/* eslint-disable no-plusplus */
function generateVitalSigns(min, max, length) {
  const vitalSigns = [];
  for (let i = 0; i < length; i++) {
    const value = Math.floor(Math.random() * (max - min + 1)) + min;
    vitalSigns.push({
      timeStamp: '2023-02-28T12:36:12.000Z',
      __typename: 'VitalSign',
      alarmStatus: null,
      value: `${value}`,
    });
  }
  return vitalSigns;
}

export const monitor = {
  GetMonitorDashboard: () => ({
    getMonitorDashboard: [
      {
        name: 'temperature',
        value: [{
          timeStamp: '2023-02-28T12:44:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.3',
        }, {
          timeStamp: '2023-02-28T12:45:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.4',
        }, {
          timeStamp: '2023-02-28T12:46:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.4',
        }, {
          timeStamp: '2023-02-28T12:47:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.4',
        }, {
          timeStamp: '2023-02-28T12:48:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.5',
        }, {
          timeStamp: '2023-02-28T12:49:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.5',
        }, {
          timeStamp: '2023-02-28T12:50:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.5',
        }, {
          timeStamp: '2023-02-28T12:51:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.5',
        }, {
          timeStamp: '2023-02-28T12:52:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.5',
        }, {
          timeStamp: '2023-02-28T12:53:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.5',
        }, {
          timeStamp: '2023-02-28T12:54:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.5',
        }, {
          timeStamp: '2023-02-28T12:55:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.6',
        }, {
          timeStamp: '2023-02-28T12:56:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.6',
        }, {
          timeStamp: '2023-02-28T12:57:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '33.6',
        }, {
          timeStamp: '2023-02-28T12:58:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '34.5',
        }, {
          timeStamp: '2023-02-28T12:59:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '34.8',
        }, {
          timeStamp: '2023-02-28T13:00:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '34.9',
        }, {
          timeStamp: '2023-02-28T13:01:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35',
        }, {
          timeStamp: '2023-02-28T13:02:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.1',
        }, {
          timeStamp: '2023-02-28T13:03:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.2',
        }, {
          timeStamp: '2023-02-28T13:04:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.2',
        }, {
          timeStamp: '2023-02-28T13:05:33.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.3',
        }, {
          timeStamp: '2023-02-28T13:06:34.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.3',
        }, {
          timeStamp: '2023-02-28T13:07:34.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.3',
        }, {
          timeStamp: '2023-02-28T13:08:34.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.4',
        }, {
          timeStamp: '2023-02-28T13:09:34.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.4',
        }, {
          timeStamp: '2023-02-28T13:10:34.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.4',
        }, {
          timeStamp: '2023-02-28T13:11:34.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.5',
        }, {
          timeStamp: '2023-02-28T13:12:34.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.5',
        }, {
          timeStamp: '2023-02-28T13:13:34.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.5',
        }, {
          timeStamp: '2023-02-28T13:14:34.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '35.6',
        },
        ],
        __typename: 'MonitorDashboardItem',
      },
      {
        name: 'heartRate',
        value: [{
          timeStamp: '2023-02-28T12:36:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T12:37:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '102',
        }, {
          timeStamp: '2023-02-28T12:38:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '74',
        }, {
          timeStamp: '2023-02-28T12:39:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '102',
        }, {
          timeStamp: '2023-02-28T12:40:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '100',
        }, {
          timeStamp: '2023-02-28T12:41:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '77',
        }, {
          timeStamp: '2023-02-28T12:42:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '82',
        }, {
          timeStamp: '2023-02-28T12:43:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '78',
        }, {
          timeStamp: '2023-02-28T12:44:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:45:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '104',
        }, {
          timeStamp: '2023-02-28T12:46:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '115',
        }, {
          timeStamp: '2023-02-28T12:47:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '106',
        }, {
          timeStamp: '2023-02-28T12:48:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '105',
        }, {
          timeStamp: '2023-02-28T12:49:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T12:50:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '109',
        }, {
          timeStamp: '2023-02-28T12:51:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '101',
        }, {
          timeStamp: '2023-02-28T12:52:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T12:53:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '102',
        }, {
          timeStamp: '2023-02-28T12:54:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '100',
        }, {
          timeStamp: '2023-02-28T12:55:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '78',
        }, {
          timeStamp: '2023-02-28T12:56:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '80',
        }, {
          timeStamp: '2023-02-28T12:57:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '81',
        }, {
          timeStamp: '2023-02-28T12:58:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '101',
        }, {
          timeStamp: '2023-02-28T12:59:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '82',
        }, {
          timeStamp: '2023-02-28T13:00:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '92',
        }, {
          timeStamp: '2023-02-28T13:01:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '82',
        }, {
          timeStamp: '2023-02-28T13:02:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '81',
        }, {
          timeStamp: '2023-02-28T13:03:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '100',
        }, {
          timeStamp: '2023-02-28T13:04:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '81',
        }, {
          timeStamp: '2023-02-28T13:05:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '80',
        }, {
          timeStamp: '2023-02-28T13:06:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '80',
        }],
        __typename: 'MonitorDashboardItem',
      },
      {
        name: 'respiratoryRate',
        value: [{
          timeStamp: '2023-02-28T12:36:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '18',
        }, {
          timeStamp: '2023-02-28T12:37:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '14',
        }, {
          timeStamp: '2023-02-28T12:38:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T12:39:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '19',
        }, {
          timeStamp: '2023-02-28T12:40:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '11',
        }, {
          timeStamp: '2023-02-28T12:41:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T12:42:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '19',
        }, {
          timeStamp: '2023-02-28T12:43:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '18',
        }, {
          timeStamp: '2023-02-28T12:44:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '21',
        }, {
          timeStamp: '2023-02-28T12:45:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '0',
        }, {
          timeStamp: '2023-02-28T12:46:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '40',
        }, {
          timeStamp: '2023-02-28T12:47:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '0',
        }, {
          timeStamp: '2023-02-28T12:48:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T12:49:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '16',
        }, {
          timeStamp: '2023-02-28T12:50:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '19',
        }, {
          timeStamp: '2023-02-28T12:51:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '22',
        }, {
          timeStamp: '2023-02-28T12:52:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '22',
        }, {
          timeStamp: '2023-02-28T12:53:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '18',
        }, {
          timeStamp: '2023-02-28T12:54:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '18',
        }, {
          timeStamp: '2023-02-28T12:55:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T12:56:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T12:57:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T12:58:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '16',
        }, {
          timeStamp: '2023-02-28T12:59:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T13:00:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T13:01:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '16',
        }, {
          timeStamp: '2023-02-28T13:02:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T13:03:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '18',
        }, {
          timeStamp: '2023-02-28T13:04:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '17',
        }, {
          timeStamp: '2023-02-28T13:05:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '20',
        }, {
          timeStamp: '2023-02-28T13:06:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '19',
        },
        ],
        __typename: 'MonitorDashboardItem',
      },
      {
        name: 'oxygenSaturation',
        value: [{
          timeStamp: '2023-02-28T12:36:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '94',
        }, {
          timeStamp: '2023-02-28T12:37:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '94',
        }, {
          timeStamp: '2023-02-28T12:38:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T12:39:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T12:40:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T12:41:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T12:42:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T12:43:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '94',
        }, {
          timeStamp: '2023-02-28T12:44:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T12:45:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T12:46:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '81',
        }, {
          timeStamp: '2023-02-28T12:47:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '100',
        }, {
          timeStamp: '2023-02-28T12:48:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T12:49:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '98',
        }, {
          timeStamp: '2023-02-28T12:50:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '100',
        }, {
          timeStamp: '2023-02-28T12:51:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '96',
        }, {
          timeStamp: '2023-02-28T12:52:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '100',
        }, {
          timeStamp: '2023-02-28T12:53:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '92',
        }, {
          timeStamp: '2023-02-28T12:54:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '94',
        }, {
          timeStamp: '2023-02-28T12:55:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '93',
        }, {
          timeStamp: '2023-02-28T12:56:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '93',
        }, {
          timeStamp: '2023-02-28T12:57:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '93',
        }, {
          timeStamp: '2023-02-28T12:58:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '93',
        }, {
          timeStamp: '2023-02-28T12:59:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '93',
        }, {
          timeStamp: '2023-02-28T13:00:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '93',
        }, {
          timeStamp: '2023-02-28T13:01:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '94',
        }, {
          timeStamp: '2023-02-28T13:02:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '93',
        }, {
          timeStamp: '2023-02-28T13:03:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '93',
        }, {
          timeStamp: '2023-02-28T13:04:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T13:05:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        }, {
          timeStamp: '2023-02-28T13:06:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '95',
        },
        ],
        __typename: 'MonitorDashboardItem',
      },
      {
        name: 'systolicArterialPressure',
        value: [{
          timeStamp: '2023-02-28T12:36:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:37:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:38:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:39:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:40:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:41:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:42:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:43:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:44:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:45:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:46:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:47:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:48:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:49:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:50:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:51:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:52:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:53:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:54:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:55:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:56:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '64',
        }, {
          timeStamp: '2023-02-28T12:57:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        }, {
          timeStamp: '2023-02-28T12:58:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        }, {
          timeStamp: '2023-02-28T12:59:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        }, {
          timeStamp: '2023-02-28T13:00:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        }, {
          timeStamp: '2023-02-28T13:01:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        }, {
          timeStamp: '2023-02-28T13:02:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        }, {
          timeStamp: '2023-02-28T13:03:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        }, {
          timeStamp: '2023-02-28T13:04:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        }, {
          timeStamp: '2023-02-28T13:05:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        }, {
          timeStamp: '2023-02-28T13:06:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '75',
        },
        ],
        __typename: 'MonitorDashboardItem',
      },
      {
        name: 'diastolicArterialPressure',
        value: [{
          timeStamp: '2023-02-28T12:36:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '66',
        }, {
          timeStamp: '2023-02-28T12:37:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:38:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:39:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:40:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:41:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:42:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:43:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:44:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:45:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:46:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:47:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:48:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:49:12.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:50:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:51:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:52:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:53:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:54:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:55:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:56:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '69',
        }, {
          timeStamp: '2023-02-28T12:57:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T12:58:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T12:59:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T13:00:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T13:01:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T13:02:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T13:03:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T13:04:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T13:05:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        }, {
          timeStamp: '2023-02-28T13:06:13.000Z',
          __typename: 'VitalSign',
          alarmStatus: null,
          value: '76',
        },
        ],
        __typename: 'MonitorDashboardItem',
      },
      {
        name: 'meanArterialPressure',
        value: [{
          timeStamp: '2023-02-28T12:36:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '80',
        }, {
          timeStamp: '2023-02-28T12:37:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:38:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:39:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:40:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:41:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:42:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:43:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:44:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:45:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:46:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:47:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:48:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:49:12.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:50:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:51:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:52:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:53:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:54:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:55:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:56:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '89',
        }, {
          timeStamp: '2023-02-28T12:57:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T12:58:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T12:59:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T13:00:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T13:01:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T13:02:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T13:03:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T13:04:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T13:05:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        }, {
          timeStamp: '2023-02-28T13:06:13.000Z',
          _typename: 'VitalSign',
          alarmStatus: null,
          value: '91',
        },
        ],
        __typename: 'MonitorDashboardItem',
      },
      {
        name: 'carbonDioxide',
        value: generateVitalSigns(30, 45, 30),
      },
    ],
  }),
  SaveBedPreferences: (onCompleted) => [() => onCompleted(), { loading: false }],
};
