export const names = {
  adjust: 'adjust.svg',
  air: 'air.svg',
  alert: 'alert.svg',
  arrowLeft: 'arrowLeft.svg',
  arrowRight: 'arrowRight.svg',
  arrowUp: 'arrowUp.svg',
  arterialPressure: 'bloodPressure.svg',
  assistentialManagement: 'assistentialManagement.svg',
  bloodPressure: 'bloodPressure.svg',
  boxArrowDown: 'boxArrowDown.svg',
  close: 'close.svg',
  config: 'config.svg',
  connectionOff: 'connectionOff.svg',
  connectionOn: 'connectionOn.svg',
  dashboard: 'dashboard.svg',
  diastolicArterialPressure: 'diastolicBloodPressure.svg',
  editA: 'editA.svg',
  editB: 'editB.svg',
  excel: 'excel.svg',
  configFile: 'configFile.svg',
  export: 'export.svg',
  erp: 'erp.svg',
  es: 'es.svg',
  ecg: 'ecg.svg',
  free: 'free.svg',
  heartBroken: 'heartBroken.svg',
  heartRate: 'heartRate.svg',
  history: 'history.svg',
  home: 'home.svg',
  infusion: 'infusion.svg',
  language: 'language.svg',
  level1: 'level1.svg',
  level2: 'level2.svg',
  level3: 'level3.svg',
  level4: 'level4.svg',
  level5: 'level5.svg',
  loadingCircle: 'loadingCircle.svg',
  loadingHeart: 'loadingHeart.svg',
  logout: 'logout.svg',
  log: 'log.svg',
  meanArterialPressure: 'mediumBloodPressure.svg',
  mediumBloodPressure: 'mediumBloodPressure.svg',
  monitor: 'monitor.svg',
  orchestra1: 'orchestra1.svg',
  oxygenSaturation: 'saturation.svg',
  carbonDioxide: 'co2.svg',
  pt: 'pt.svg',
  pulmonaryRate: 'respiratoryRate.svg',
  plus: 'plus.svg',
  respiratoryRate: 'respiratoryRate.svg',
  saturation: 'saturation.svg',
  search: 'search.svg',
  searchWhite: 'searchWhite.svg',
  systolicArterialPressure: 'systolicBloodPressure.svg',
  temperature: 'temperature.svg',
  trash: 'trash.svg',
  triangleArrowDown: 'triangleArrowDown.svg',
  triangleSortBy: 'triangleSortBy.svg',
  user: 'user.svg',
  multipleUsers: 'multipleUsers.svg',
  userManagement: 'userManagement.svg',
  visibilityOff: 'visibilityOff.svg',
  visibilityOffTemp: 'visibilityOffTemp.svg',
  visibilityOn: 'visibilityOn.svg',
  warning: 'warning.svg',
  chain: 'chain.svg',
  chainMarked: 'chain-marked.svg',
  sheets: 'sheets.svg',
  hospital: 'hospital.svg',
  network: 'network.svg',
  save: 'save.svg',
  alfamed: 'vendors/alfamed.png',
  baxter: 'vendors/baxter.svg',
  bbraun: 'vendors/bbraun.svg',
  bionet: 'vendors/bionet.png',
  comen: 'vendors/comen.svg',
  contec: 'vendors/contec.png',
  covidien: 'vendors/covidien.svg',
  cmosdrake: 'vendors/cmosdrake.webp',
  dixtal: 'vendors/dixtal.svg',
  drager: 'vendors/drager.svg',
  edwards: 'vendors/edwards.svg',
  fresenius: 'vendors/fresenius.svg',
  ge: 'vendors/ge.svg',
  getinge: 'vendors/getinge.png',
  instramed: 'vendors/instramed.svg',
  lifemed: 'vendors/lifemed.png',
  magnamed: 'vendors/magnamed.png',
  maquet: 'vendors/maquet.svg',
  medtronic: 'vendors/medtronic.svg',
  mindray: 'vendors/mindray.svg',
  nihom: 'vendors/nihom.svg',
  philips: 'vendors/philips.svg',
  prolife: 'vendors/prolife.png',
  tecme: 'vendors/tecme.png',
  samtronic: 'vendors/samtronic.png',
  welchallyn: 'vendors/welchallyn.png',
};
