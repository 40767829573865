export const bed = {
  Patient: () => ({
    patient: {
      pid: '84773294',
      fullName: 'Rayanne Jales Gadelha',
      bed: '101',
      pointOfCare: 'UTI Geral',
      initials: 'R-J-G',
      __typename: 'Patient',
    },
  }),
};
