import { pageHome } from './pageHome';
import { getPep } from './getPep';
import { user } from './user';
import { alarmsBed } from './alarmsBed';
import { monitor } from './monitor';
import { historyMonitor } from './getHistoryByBed';
import { tableAlarms } from './tableAlarms';
import { patientRecord } from './patientRecord';
import { bed } from './bed';
import { air } from './air';
import { infusion } from './infusion';
import { getAllHospitalsGroup } from './deployment';
import { ECG } from './ecg';
import { ecgIi } from './ecgII';

export default {
  ...pageHome,
  ...getPep,
  ...alarmsBed,
  ...monitor,
  ...user,
  ...historyMonitor,
  ...bed,
  ...tableAlarms,
  ...air,
  ...patientRecord,
  ...infusion,
  ...getAllHospitalsGroup,
  ...ECG,
  ...ecgIi,
};
