import * as ecgWaves from './ecg_waves';
import { getECG } from './dataGenerator';

export const ecgIi = {
  EcgIi: ({ ids }) => {
    const bed = ids.split('#')[2];
    const interval = {
      [bed]: undefined,
    };
    let callback = () => { };
    function propagateData() {
      callback({
        ecgIi: {
          ids,
          wave: getECG(ecgWaves.Ii),
        },
      });
    }

    if (interval[bed]) {
      clearInterval(interval[bed]);
    }

    interval[bed] = setInterval(propagateData, 6100);

    return {
      onData: (func) => {
        callback = func;
      },
      unsubscribe: () => {
        clearInterval(interval[bed]);
      },
    };
  },
};
