const today = (miliseconds) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };
  const date = new Date().getTime() - miliseconds;
  return Intl.DateTimeFormat('pt-BR', options).format(date);
};

export const alarmsBed = {

  GetPresetAvailableToBed: () => ({
    getPresetAvailableToBed: [
      {
        id: 3090,
        presetName: `pietro_leal ${today(1200000)}`,
        specialty: 'Pediátrica mista',
        items: [
          {
            id: 14312,
            vitalSign: 'systolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 62,
            presetLower: 142,
            presetHigher: 194,
            presetVeryHigher: 255,
            presetMax: 300,
            __typename: 'PresetsItems',
          },
          {
            id: 14313,
            vitalSign: 'diastolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 43,
            presetLower: 80,
            presetHigher: 100,
            presetVeryHigher: 130,
            presetMax: 150,
            __typename: 'PresetsItems',
          },
          {
            id: 14314,
            vitalSign: 'meanArterialPressure',
            presetMin: 0,
            presetVeryLower: 62,
            presetLower: 90,
            presetHigher: 130,
            presetVeryHigher: 200,
            presetMax: 250,
            __typename: 'PresetsItems',
          },
          {
            id: 14315,
            vitalSign: 'heartRate',
            presetMin: 0,
            presetVeryLower: 39,
            presetLower: 60,
            presetHigher: 130,
            presetVeryHigher: 180,
            presetMax: 250,
            __typename: 'PresetsItems',
          },
          {
            id: 14316,
            vitalSign: 'oxygenSaturation',
            presetMin: 87,
            presetVeryLower: 88,
            presetLower: 96,
            presetHigher: null,
            presetVeryHigher: null,
            presetMax: 100,
            __typename: 'PresetsItems',
          },
          {
            id: 14317,
            vitalSign: 'respiratoryRate',
            presetMin: 0,
            presetVeryLower: 9,
            presetLower: 40,
            presetHigher: 70,
            presetVeryHigher: 80,
            presetMax: 90,
            __typename: 'PresetsItems',
          },
          {
            id: 14318,
            vitalSign: 'temperature',
            presetMin: 30,
            presetVeryLower: 34.9,
            presetLower: 36.1,
            presetHigher: 37.9,
            presetVeryHigher: 40.9,
            presetMax: 42,
            __typename: 'PresetsItems',
          },
        ],
        __typename: 'Presets',
      },
      {
        id: 1,
        presetName: 'Orchestra Default',
        specialty: 'Geral',
        items: [
          {
            id: 1,
            vitalSign: 'systolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 89,
            presetLower: 110,
            presetHigher: 126,
            presetVeryHigher: 146,
            presetMax: 300,
            __typename: 'PresetsItems',
          },
          {
            id: 2,
            vitalSign: 'diastolicArterialPressure',
            presetMin: 0,
            presetVeryLower: 59,
            presetLower: 80,
            presetHigher: 86,
            presetVeryHigher: 101,
            presetMax: 150,
            __typename: 'PresetsItems',
          },
          {
            id: 3,
            vitalSign: 'meanArterialPressure',
            presetMin: 0,
            presetVeryLower: 69,
            presetLower: 90,
            presetHigher: 99,
            presetVeryHigher: 116,
            presetMax: 250,
            __typename: 'PresetsItems',
          },
          {
            id: 4,
            vitalSign: 'heartRate',
            presetMin: 0,
            presetVeryLower: 39,
            presetLower: 50,
            presetHigher: 91,
            presetVeryHigher: 121,
            presetMax: 250,
            __typename: 'PresetsItems',
          },
          {
            id: 5,
            vitalSign: 'oxygenSaturation',
            presetMin: 87,
            presetVeryLower: 88,
            presetLower: 96,
            presetHigher: null,
            presetVeryHigher: null,
            presetMax: 100,
            __typename: 'PresetsItems',
          },
          {
            id: 6,
            vitalSign: 'respiratoryRate',
            presetMin: 0,
            presetVeryLower: 9,
            presetLower: 12,
            presetHigher: 20,
            presetVeryHigher: 31,
            presetMax: 90,
            __typename: 'PresetsItems',
          },
          {
            id: 7,
            vitalSign: 'temperature',
            presetMin: 30,
            presetVeryLower: 34.9,
            presetLower: 36.1,
            presetHigher: 37.9,
            presetVeryHigher: 40.9,
            presetMax: 42,
            __typename: 'PresetsItems',
          },
        ],
        __typename: 'Presets',
      },
    ],
  }),
  CreateTemporaryPreset: (onCompleted) => [() => onCompleted(), { loading: false }],
};
