/* eslint-disable no-plusplus */
function randomVal(min, max) {
  const calc = Math.random() * (max - min) + min;
  return Number(calc.toFixed(0));
}

export function generateBed(icuId, bedId, patientInitials, patientId, bedStatus, pepStatus) {
  return {
    id: `${icuId}#${bedId}`,
    status: bedStatus,
    label: bedId,
    patient: {
      initials: patientInitials,
      pid: patientId,
    },
    pepStatus,
    heartRate: {
      id: `${icuId}#${bedId}#heartRate`,
      alarmStatus: 'normal',
      timeStamp: '2023-02-10T01:02:53.149Z',
      value: '80',
      type: 'heartRate',
      __typename: 'VitalSign',
    },
    respiratoryRate: {
      id: `${icuId}#${bedId}#respiratoryRate`,
      alarmStatus: 'normal',
      timeStamp: '2023-02-10T01:02:53.149Z',
      value: '17.00',
      type: 'respiratoryRate',
      __typename: 'VitalSign',
    },
    arterialPressure: {
      id: `${icuId}#${bedId}#arterialPressure`,
      alarmStatus: 'normal',
      timeStamp: '2023-02-10T01:02:53.149Z',
      value: '104.00/141.00',
      type: 'arterialPressure',
      __typename: 'VitalSign',
    },
    meanArterialPressure: {
      id: `${icuId}#${bedId}#meanArterialPressure`,
      alarmStatus: 'normal',
      timeStamp: '2023-02-10T01:02:53.149Z',
      value: '82.00',
      type: 'meanArterialPressure',
      __typename: 'VitalSign',
    },
    oxygenSaturation: {
      id: `${icuId}#${bedId}#oxygenSaturation`,
      alarmStatus: 'normal',
      timeStamp: '2023-02-10T01:02:53.149Z',
      value: '96',
      type: 'oxygenSaturation',
      __typename: 'VitalSign',
    },
    temperature: {
      id: `${icuId}#${bedId}#temperature`,
      alarmStatus: 'normal',
      timeStamp: '2023-02-10T01:02:53.149Z',
      value: '36.52',
      type: 'temperature',
      __typename: 'VitalSign',
    },
    carbonDioxide: {
      id: `${icuId}#${bedId}#carbonDioxide`,
      alarmStatus: 'normal',
      timeStamp: '2023-02-10T01:02:53.149Z',
      value: '35',
      type: 'carbonDioxide',
      __typename: 'VitalSign',
    },

    __typename: 'Bed',
  };
}

export function generateIcu(hospitalId, icuLabel) {
  const icuLabelSlug = icuLabel.toLowerCase().replace(' ', '_').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return {
    id: `${hospitalId}#${icuLabelSlug}`,
    label: icuLabel,
    beds: [],
    __typename: 'ICU',
  };
}

export function generateHistoryBed(max = 10, selectedTime) {
  const history = [];
  const date = new Date();
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < max; i++) {
    history.push({
      orchestraTimestamp: new Date(date.setMinutes(date.getMinutes() - selectedTime)),
      heartRate: randomVal(50, 70),
      respiratoryRate: randomVal(12, 20),
      systolicArterialPressure: randomVal(110, 120),
      diastolicArterialPressure: randomVal(80, 86),
      meanArterialPressure: randomVal(90, 98),
      oxygenSaturation: randomVal(90, 95),
      temperature: randomVal(34, 37),
      carbonDioxide: randomVal(22, 50),
      __typename: 'GetHistoryByBed',
    });
  }
  return history.reverse();
}

export function generateAir(max = 30) {
  const airData = [];
  const dateStart = new Date();
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < max; i++) {
    airData.push({
      timeStamp: new Date(dateStart.getTime() + i * 6000).toISOString(),
      ppeak: randomVal(50, 70),
      peep: randomVal(50, 70),
      rrate: randomVal(50, 70),
      spo2: randomVal(50, 70),
      vte: randomVal(50, 70),
      vmi: randomVal(50, 70),
      vti: randomVal(50, 70),
      pplat: randomVal(50, 70),
      pmean: randomVal(50, 70),
      ri: randomVal(50, 70),
      re: randomVal(50, 70),
      cdyn: randomVal(50, 70),
      cstat: randomVal(50, 70),
    });
  }
  return airData;
}

export function generateAllIcus() {
  const hospitals = [];
  const hospitalsIcus = [
    {
      id: 'rede_carenet/hospital_elizabeth_blackwell',
      label: 'UTI Geral',
      startsAt: 100,
      beds: [
        {
          patientInitials: 'R-J-G', state: 'ACTIVE', pepStatus: 'danger', patientId: '84773294',
        },
        {
          patientInitials: '', state: 'ACTIVE_UNPLUGGED', pepStatus: 'normal', patientId: 1102,
        },
        {
          patientInitials: 'T-V-J', state: 'ACTIVE', pepStatus: 'danger', patientId: '82609948',
        },
        {
          patientInitials: 'E-F-S', state: 'ACTIVE', pepStatus: 'normal', patientId: '7247283',
        },
        {
          patientInitials: 'I-S-C', state: 'ACTIVE', pepStatus: 'normal', patientId: '97216425',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1106,
        },
        {
          patientInitials: 'I-F-C', state: 'ACTIVE', pepStatus: 'alert', patientId: '56005337',
        },
        {
          patientInitials: 'A-S-W', state: 'ON_HOLD', pepStatus: '', patientId: 1108,
        },
        {
          patientInitials: 'P-B-B', state: 'ACTIVE', pepStatus: 'normal', patientId: '23581844',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1110,
        },
        {
          patientInitials: 'B-B-S', state: 'ACTIVE', pepStatus: 'normal', patientId: '90943818',
        },
        {
          patientInitials: 'J-N-P', state: 'ACTIVE', pepStatus: 'normal', patientId: '19460500',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1113,
        },
        {
          patientInitials: 'A-N', state: 'ACTIVE', pepStatus: 'normal', patientId: '82535692',
        },
        {
          patientInitials: '', state: 'DISABLED', pepStatus: '', patientId: 1115,
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1116,
        },
        {
          patientInitials: 'A-A-R', state: 'ACTIVE', pepStatus: 'normal', patientId: '27383565',
        },
        {
          patientInitials: 'H-D-F', state: 'ACTIVE', pepStatus: 'normal', patientId: '51785713',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1119,
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1120,
        },
      ],
    },
    {
      id: 'rede_carenet/hospital_elizabeth_blackwell',
      label: 'UTI Pediátrica',
      startsAt: 200,
      beds: [
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1201,
        },
        {
          patientInitials: 'L-F-E', state: 'ACTIVE', pepStatus: 'normal', patientId: '51785713',
        },
        {
          patientInitials: 'A-C-F', state: 'ACTIVE', pepStatus: 'normal', patientId: '76307998',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1204,
        },
        {
          patientInitials: 'I-B-B', state: 'ACTIVE', pepStatus: 'danger', patientId: '10999573',
        },
        {
          patientInitials: 'M-F-B', state: 'ACTIVE', pepStatus: 'danger', patientId: '61558807',
        },
        {
          patientInitials: 'K-L-M', state: 'ON_HOLD', pepStatus: '', patientId: 1207,
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1208,
        },
      ],
    },
    {
      id: 'rede_carenet/hospital_sao_marcio',
      label: 'UTI Geral',
      startsAt: 300,
      beds: [
        {
          patientInitials: 'I-S-C', state: 'ACTIVE', pepStatus: 'normal', patientId: '97216425',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1102,
        },
        {
          patientInitials: 'R-J-G', state: 'ACTIVE', pepStatus: 'danger', patientId: '84773294',
        },
        {
          patientInitials: 'T-V-J', state: 'ACTIVE', pepStatus: 'normal', patientId: '82609948',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1106,
        },
        {
          patientInitials: 'I-F-C', state: 'ACTIVE', pepStatus: 'normal', patientId: '56005337',
        },
        {
          patientInitials: 'G-T-M', state: 'ON_HOLD', pepStatus: '', patientId: 1108,
        },
        {
          patientInitials: 'E-F-S', state: 'ACTIVE', pepStatus: 'normal', patientId: '7247283',
        },
        {
          patientInitials: 'P-B-B', state: 'ACTIVE', pepStatus: 'normal', patientId: '23581844',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1110,
        },
        {
          patientInitials: 'B-B-S', state: 'ACTIVE', pepStatus: 'normal', patientId: '90943818',
        },
        {
          patientInitials: 'J-N-P', state: 'ACTIVE', pepStatus: 'normal', patientId: '19460500',
        },
        {
          patientInitials: 'A-H-P', state: 'INACTIVE', pepStatus: '', patientId: 1113,
        },
        {
          patientInitials: 'A-N', state: 'ACTIVE', pepStatus: 'normal', patientId: '82535692',
        },
        {
          patientInitials: '', state: 'DISABLED', pepStatus: '', patientId: 1115,
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1116,
        },
        {
          patientInitials: 'A-A-R', state: 'ACTIVE', pepStatus: 'normal', patientId: '27383565',
        },
        {
          patientInitials: 'H-D-F', state: 'ACTIVE', pepStatus: 'normal', patientId: '51785713',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1119,
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1120,
        },
      ],
    },
    {
      id: 'rede_carenet/hospital_sao_marcio',
      label: 'UTI Adulto',
      startsAt: 400,
      beds: [
        {
          patientInitials: 'L-F-E', state: 'ACTIVE', pepStatus: 'normal', patientId: '5769247',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1201,
        },
        {
          patientInitials: 'A-C-F', state: 'ACTIVE', pepStatus: 'normal', patientId: '40139576',
        },
        {
          patientInitials: 'I-B-B', state: 'ACTIVE', pepStatus: 'danger', patientId: '22587699',
        },
        {
          patientInitials: 'M-F-B', state: 'ACTIVE', pepStatus: 'danger', patientId: '60651284',
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1204,
        },
        {
          patientInitials: 'R-L-L', state: 'ON_HOLD', pepStatus: '', patientId: 1207,
        },
        {
          patientInitials: '', state: 'FREE', pepStatus: '', patientId: 1208,
        },
      ],
    },
  ];
  let icu;
  let icuLabelSlug = '';
  hospitalsIcus.forEach((h) => {
    icu = generateIcu(h.id, h.label);
    icuLabelSlug = h.label.toLowerCase().replace(' ', '_').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    let icuCount = 1;
    h.beds.forEach((b) => {
      icu.beds.push(generateBed(`${h.id}#${icuLabelSlug}`, h.startsAt + icuCount, b.patientInitials, b.patientId, b.state, b.pepStatus));
      icuCount += 1;
    });
    hospitals.push(icu);
  });
  return hospitals;
}

export function getIcu(icuId) {
  const allIcus = generateAllIcus();
  return allIcus.filter((icu) => icu.id === icuId);
}

export function getECG(wave) {
  return [...wave];
}
