import {
  ApolloClient, InMemoryCache, from, concat,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { getMainDefinition } from '@apollo/client/utilities';
import { handleCommunicationErrors } from 'utils/handleCommunicationErrors';
import { retryConfig } from 'config/constants';
import { authMiddleware } from './middlewares/authMiddleware';

export default function client() {
  const uriHttp = process.env.REACT_APP_BFF_HTTP;
  const uriWebsocket = process.env.REACT_APP_BFF_WEBSOCKET;

  const httpLink = createUploadLink({
    uri: uriHttp,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: uriWebsocket,
    }),
  );

  const retryLink = new RetryLink({
    delay: {
      initial: retryConfig.initial,
      max: retryConfig.maxTime,
      jitter: retryConfig.jitter,
    },
    attempts: {
      max: retryConfig.maxAttempts,
    },
  }).split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const errorLink = onError((error) => handleCommunicationErrors(error));

  const link = from([errorLink, retryLink]);

  return new ApolloClient({
    link: concat(authMiddleware, link),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });
}
