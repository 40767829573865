import React from 'react';
import { useSelector } from 'react-redux';
import { useLanguage } from 'components/layout/SelectLanguage/hooks';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';

import { StyledToastContainer } from 'components/core/Toast';

import { ThemeProvider } from 'styled-components';
import Routes from 'config/routes';

import useApolloClient from 'services/apollo/hooks/useApolloClient';
import ExitModal from 'components/layout/ExitModal';
import { UserProvider } from 'services/cognito/user';

function ApolloEnvironment() {
  const apolloClient = useApolloClient();

  return (
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <Routes />
        <StyledToastContainer />
        <ExitModal />
      </HelmetProvider>
    </ApolloProvider>
  );
}

function App() {
  const theme = useSelector((state) => state.theme);

  useLanguage();

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <UserProvider>
          <ApolloEnvironment />
        </UserProvider>
      </DndProvider>
    </ThemeProvider>
  );
}

export default App;
