import React, { useEffect, useState } from 'react';

import Button, { models } from 'components/core/Button';
import Modal from 'components/layout/Modal';
import Icon, { names } from 'components/core/Icon';

import { useTranslation } from 'react-i18next';
import { zIndex } from 'config/constants';
import { debounce } from 'utils/debounce';
// import logUserAction, { USER_LOGOUT } from 'services/apollo/log/logUserAction';
import { paths } from 'config/routes/constants';
// import { useApolloClient } from '@apollo/client';
// import useUser from 'services/cognito/user';
import listenEvents from './helpers/listenEvents';
import shouldSessionExpire from './helpers/shouldSessionExpire';
import { events, thirtyMinutes } from './constants';

import {
  Container,
  Title,
  Text,
} from './styles';

export default function ExpiredSession() {
  const [shouldShowModal, setShouldShowModal] = useState(false);
  // const apolloClient = useApolloClient();
  // const { user } = useUser();

  const { t } = useTranslation();
  const handleExit = () => {
    window.localStorage.clear();
    window.location.replace(paths.login);
  };
  useEffect(() => {
    if (shouldSessionExpire()) {
      const debouncedLogOut = debounce(() => {
        setShouldShowModal(true);
        // logUserAction(apolloClient, USER_LOGOUT);
        // user.logOut();
      }, thirtyMinutes);

      debouncedLogOut();
      listenEvents(events, () => debouncedLogOut());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      zIndex={zIndex.lvMax}
      canClose={false}
      blurBackground
      show={shouldShowModal}
    >
      <Container>
        <Title>
          <Icon name={names.warning} width="35px" height="35px" />
          <h2>{t('expiredSession')}</h2>
        </Title>
        <Text>
          {t('text.expiredSession')}
        </Text>
        <Button
          type="button"
          margin="0 auto"
          onClick={() => handleExit()}
          text="OK"
          model={models.modelB}
        />
      </Container>
    </Modal>
  );
}
